<template>
  <div class="legal-entities">
    <legal-entity-search>
      <template v-slot>
        <div class="mb-3">
          <div class="h3 mb-0">
            Юридические лица клиник
          </div>
        </div>
      </template>

      <template v-slot:button>
        <b-button-group>
          <b-button
            v-if="!checkFeature(FEATURES_FOR_LEGAL_ENTITIES.hideBtnExport)"
            variant="outline-primary"
            :type="$const.PRIMARY_BUTTON"
            class="legal-entities__nowrap"
            @click="exportRegisters"
          >
            Экспорт реестров
          </b-button>

          <b-button
            v-if="checkFeature(FEATURES_FOR_LEGAL_ENTITIES.abilityCreateLegalEntity)"
            variant="primary"
            :type="$const.PRIMARY_BUTTON"
            class="legal-entities__nowrap ml-1"
            @click="$router.push('/legal-entities/create')"
          >
            Создать юр лицо
          </b-button>
        </b-button-group>
        <!--        <base-button-->
        <!--          :type="$const.SECONDARY_BUTTON"-->
        <!--          class="add-button"-->
        <!--          @click="exportRegisters"-->
        <!--        >-->
        <!--          <span>-->
        <!--            Экспорт реестров-->
        <!--          </span>-->
        <!--        </base-button>-->
        <!--        <router-link-->
        <!--          -->
        <!--          class="link-btn"-->
        <!--          -->
        <!--        >-->
        <!--          <base-button-->
        <!--            :type="$const.PRIMARY_BUTTON"-->
        <!--            class="add-button"-->
        <!--            @click="openEditModal"-->
        <!--          >-->
        <!--            <icon-cross-->
        <!--              class="crm-icon-cross"-->
        <!--            />-->
        <!--            <span>-->
        <!--              Создать юр лицо-->
        <!--            </span>-->
        <!--          </base-button>-->
        <!--        </router-link>-->
      </template>

      <template v-slot:legalEntities>
        <legal-entities-items />
      </template>
    </legal-entity-search>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';

import LegalEntitySearch from '@/components/LegalEntities/LegalEntitySearch';
import LegalEntitiesItems from '@/components/LegalEntities/LegalEntitiesItems';

import { FEATURES_FOR_LEGAL_ENTITIES } from '@/helpers/consts';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

export default {
  name: 'LegalEntities',
  page: {
    title: 'CRM Doctis - Юридические лица клиник',
  },
  components: {
    LegalEntitySearch,
    LegalEntitiesItems,
  },
  mixins: [mixinRoles],
  data() {
    return {
      FEATURES_FOR_LEGAL_ENTITIES,
    };
  },
  methods: {
    checkFeature(key) {
      return this.checkFeatureAccess({ name: key?.name, url: key?.url });
    },
    openEditModal() {
      uiService.showModal(MODALS.DOCTOR_EDIT_MODAL, {
        name: 'DoctorEditModal',
      });
    },
    exportRegisters() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'LegalEntityReestrExportModal',
      });
    },
    async legalEntitiesFetch(clear) {
      if (this.isLoading) return;

      this.isAllItemsLoaded = false;
      this.isLoading = true;

      const prevTotal = clear ? 0 : this.totalCount;
      const prevQuery = this.query;

      try {
        await this.$store.dispatch(this.$types.DOCTORS_FETCH, {
          skip: prevTotal,
          take: this.limit,
          query: prevQuery,
          clear: !!clear,
          save: true,
        });

        if (prevTotal + this.limit > this.totalCount) {
          this.isAllItemsLoaded = true;
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;

        if (this.query !== prevQuery) this.legalEntitiesFetch(true);
      }
    },
    loadMoreItems() {
      this.legalEntitiesFetch(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.legal-entities {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__nowrap {
    white-space: nowrap;
  }
}

.crm-container-title {
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
}

.crm-title {
  @extend .page-title;
  margin-right: 30px;
}

.add-button {
  padding: 17px 25px;
  border-radius: 50px;
  box-shadow: 0px 1px 2px $gray-shadow;
  width: auto;
  margin-left: 30px;
}

.crm-icon-cross {
  margin-right: 15px;
}

.link-btn {
  text-decoration: none;
}
</style>
