<template>
  <div
    class="rounded-top bg-white border p-3 w-100 d-flex"
    :class="{'crm-info-item_deleted': entity.isDeleted}"
  >
    <div class="w-50">
      <div>
        <div class="h6 font-weight-bold">
          {{ entity.name }}
        </div>
      </div>

      <div class="mt-2 d-flex">
        <b-button
          v-if="hideBtnDetails"
          v-b-tooltip.hover
          variant="light"
          :type="$const.PRIMARY_BUTTON"
          size="sm"
          class="border border-primary mt-2 p-1"
          title="Подробнее"
          @click="$router.push(`/legal-entities/${ entity.id }`)"
        >
          <b-icon icon="info" />
        </b-button>

        <div v-if="hideBtnDelete">
          <b-button
            v-if="!entity.isDeleted"
            v-b-tooltip.hover
            variant="light"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="border border-danger mt-2 p-1"
            :class="{'ml-2': hideBtnDetails}"
            title="Удалить"
            @click="openLegalEntityCardDeleteModal(entity.id)"
          >
            <b-icon icon="trash" />
          </b-button>

          <div
            v-else
            class="mt-2"
          >
            Юр. лицо удалено
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 ml-3">
      <div class="bg-white border p-3 rounded w-100">
        <div class="font-weight-bold">
          Очные мед. услуги
        </div>
        <!-- <div class="crm-info-item__section-item">Договоры</div>
        <div class="crm-info-item__section-item">Закрывающие документы</div> -->
        <hr>
        <b-button
          size="sm"
          variant="primary"
          :type="$const.PRIMARY_BUTTON"
          @click="$router.push(`/legal-entities/import/${entity.id}`)"
        >
          Проверка реестров
        </b-button>
        <!-- <div class="crm-info-item__section-item">Прайсы</div> -->
      </div>
      <!-- <div class="crm-info-item__section">
        <div class="crm-info-item__section-title">Телемедицина</div>
        <div class="crm-info-item__section-item">Договоры</div>
        <div class="crm-info-item__section-item">Закрывающие документы</div>
        <hr/>
        <div class="crm-info-item__section-item">Сверка услуг</div>
      </div>
      <div class="crm-info-item__section">
        <div class="crm-info-item__section-title">Агенсткие договоры</div>
        <div class="crm-info-item__section-item">Договоры</div>
        <div class="crm-info-item__section-item">Закрывающие документы</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { formatTimeZone } from '@/helpers/utils';
import { parseISO, format } from '@evd3v/date-fns';

import { mixinRoles } from '@/mixins';

import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';

import { FEATURES_FOR_LEGAL_ENTITIES } from '@/helpers/consts';

export default {
  name: 'LegalEntityInfo',
  mixins: [mixinRoles],
  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },
    modePin: {
      type: Boolean,
      default: false,
    },
    isPinned: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDoctorDeleting: false,
      FEATURES_FOR_LEGAL_ENTITIES,
    };
  },
  computed: {
    hideBtnDetails() {
      return !this.checkFeature(FEATURES_FOR_LEGAL_ENTITIES.hideBtnDetails);
    },
    hideBtnDelete() {
      return !this.checkFeature(FEATURES_FOR_LEGAL_ENTITIES.hideBtnDelete);
    },
    fullname() {
      return this.entity.fullName.split(' ').join('\n');
    },
    specializations() {
      return this.entity.doctorSpecializations.map((doctorSpecialization) => doctorSpecialization.specialization.title).join(', ');
    },
    averageTime() {
      if (!this.entity.averageSeveralTime) return '-';

      const array = this.entity.averageSeveralTime.match(/[0-9]+/gmi);

      return {
        h: array[0],
        m: array[1],
        s: array[2],
      };
    },
    experience() {
      const mainSpec = this.entity.mainDoctorSpecialization;

      return mainSpec ? this.parseData(mainSpec.experience) : '—';
    },
    timeZone() {
      const { timeZone } = this.entity;

      return timeZone ? formatTimeZone(timeZone) : '—';
    },
  },
  methods: {
    checkFeature(key) {
      return this.checkFeatureAccess({ name: key?.name, url: key?.url });
    },
    parseData(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
    openWatchModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'DoctorInfoModal',
        props: {
          doctorId: this.entity.id,
          title: this.entity.fullName,
        },
      });
    },
    openEditModal() {
      uiService.showModal(MODALS.DOCTOR_EDIT_MODAL, {
        name: 'DoctorEditModal',
        props: {
          doctorId: this.entity.id,
          title: 'Редактирование врача',
        },
      });
    },
    openScheduleModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ScheduleModal',
        props: {
          doctorId: this.entity.id,
        },
      });
      // Bus.$emit('open-modal:schedule', this.entity.id);
    },
    async deleteDoctor() {
      /* eslint-disable no-restricted-globals */
      /* eslint-disable-next-line no-alert */
      if (!confirm(`Вы уверены, что хотите удалить врача ${this.entity.fullName}`)) return;

      this.isDoctorDeleting = true;

      try {
        await this.$store.dispatch(this.$types.DOCTOR_DELETE, { id: this.entity.id });
      } finally {
        this.isDoctorDeleting = false;
      }
    },
    onClickTogglePin() {
      this.$emit('toggle-pin');
    },
    async onClickDelete(id) {
      await this.$store.dispatch(this.$types.LEGAL_DELETE, id);
    },
    openLegalEntityCardDeleteModal(id) {
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'AgreeModal',
          props: {
            cb: this.onClickDelete,
            cbArgs: [id],
            title: 'Удаление юр.лица клиники',
          },
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-icon {
  cursor: pointer;
}
.crm-info-item {
  padding: 30px;
  width: 100%;
  display: flex;

  &__main-info {
    width: 300px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: $black-light;
    margin-bottom: 10px;
    padding-right: 10px;
  }

  &__contact {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $black-light;
    display: flex;
    margin-top: 20px;
  }

  &__contact-type {
    font-weight: 400;
    color: $blue;
    margin-right: 5px;
  }

  &__sections-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__section {
    width: 100%;
    margin-left: 15px;
    padding: 15px;
    box-shadow: 0px 0px 4px rgba(50, 50, 50, 0.2);
    border-radius: 6px;
    hr {
      border: none;
      color: #E0E0E0;
      background-color: #E0E0E0;
      height: 1px;
    }
  }

  &__section-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #979797;
  }

  &__section-item {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $blue;
    margin-top: 10px;
    cursor: pointer;
    text-decoration: none;
  }

  &_deleted {
    opacity: 0.5;
  }
}

.icon-delete {
  cursor: pointer;
  margin-left: 15px;
  &:hover {
    cursor: pointer;

    rect {
      stroke: #6E88F3;
    }
  }
}
</style>
